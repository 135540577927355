<template>
  <div>
    <div class="contain">
      <div style="padding: 10px; font-size: 14px; font-weight: bolder">
        状态：
        <el-select
          clearable
          size="mini"
          style="width: 130px"
          class="EquipmentMarginLeft"
          v-model="pageParams.generalStatus"
          placeholder="用户状态"
        >
          <el-option
            v-for="item in userStatus"
            :key="item.id"
            :label="item.label"
            :value="item.id"
          >
          </el-option>
        </el-select>
        角色名称：
        <el-input
          size="mini"
          v-model="pageParams.roleName"
          style="width: 130px"
          placeholder="请输入角色名称"
          class="EquipmentMarginLeft"
        ></el-input>
        创建时间：<el-date-picker
          v-model="DataPicker"
          size="mini"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          value-format="yyyy-MM-dd"
          end-placeholder="结束日期"
        >
        </el-date-picker>
        <el-button
          size="mini"
          type="primary"
          style="margin-left: 20px"
          icon="el-icon-search"
          @click="search"
          >查询</el-button
        >
        <el-button
          type="primary"
          size="mini"
          icon="el-icon-refresh"
          @click="recet"
          >重置</el-button
        >
        <el-button
          type="success"
          size="mini"
          icon="el-icon-plus"
          @click="addUser"
          >添加角色</el-button
        >
      </div>
      <el-table
        :data="roleList"
        stripe
        class="table-height-in"
        style="margin-top: 20px"
        :header-cell-style="{
          color: '#333',
          fontFamily: 'MicrosoftYaHeiUI',
          fontSize: '14px',
          fontWeight: 900,
          background: '#f8f8f9',
        }"
      >
        <el-table-column
          prop="roleId"
          label="序号"
          align="center"
          width="80"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="name"
          label="角色名称"
          sortable
        ></el-table-column>
        <el-table-column
          align="center"
          prop="code"
          label="角色编码"
          sortable
        ></el-table-column>
        <el-table-column
          align="center"
          prop="generalStatus"
          label="状态"
          sortable
        >
          <template slot-scope="{ row }">
            <el-switch
              v-model="row.generalStatus"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="0"
              @change="statusChange($event, row)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="createTime"
          label="创建时间"
          sortable
        ></el-table-column>
        <el-table-column
          align="center"
          prop="remark"
          label="备注"
          sortable
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column align="left" prop="State" label="操作">
          <template slot-scope="{ row }">
            <el-button type="text" @click="updataUser(row)" size="small"
              >编辑</el-button
            >
            <el-button
              type="text"
              v-if="row.roleId != 1"
              @click="del(row)"
              size="small"
              style="color: red"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div style="text-align: center; padding: 20px 0">
        <el-pagination
          @size-change="handleSizeChange"
          style="margin-top: 10px"
          background
          layout="sizes,prev, pager, next,jumper"
          :total="total"
          :page-sizes="[10, 30, 50, 100]"
          :page-size="pageParams.size"
          :current-page="pageParams.current"
          @current-change="currentChange"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      :title="updataStatus == 0 ? '新增角色信息' : '修改角色信息'"
      :visible.sync="dialogVisible"
      width="70%"
      :close-on-click-modal="false"
      @close="clearnF('form')"
    >
      <el-form ref="form" :model="form" label-width="100px" :rules="rule">
        <el-row>
          <el-col :span="8" style="padding-left: 20px">
            <el-form-item label="角色名称：" prop="name">
              <el-input
                  v-model="form.name"
                  type="text"
                  v-if="form.roleId == 1"
                  disabled
                  placeholder="请输入角色名称"

              ></el-input>
              <el-input
                  v-model="form.name"
                  type="text"
                  v-if="form.roleId != 1"
                  placeholder="请输入角色名称"

              ></el-input>
              <!-- <el-input v-model="form.name"></el-input> -->
            </el-form-item>
            <el-form-item label="角色编码：" prop="code">
              <el-input
                  v-model="form.code"
                  type="text"
                  v-if="form.roleId == 1"
                  disabled
                  placeholder="请输入角色编码"
              ></el-input>
              <el-input
                  v-model="form.code"
                  type="text"
                  v-if="form.roleId != 1"
                  placeholder="请输入角色编码"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item label="数据范围" prop="dataScope">
              <el-select
                  v-model="form.dataScope"
                  style="width: 100%"
                  placeholder="仅对报装和维修工单生效"
              >
                <el-option
                    v-for="item in dateScopes"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id"
                />
              </el-select>
            </el-form-item> -->

            <el-form-item label="角色级别" prop="level">
              <el-input-number
                  v-model.number="form.level"
                  :min="1"
                  type="number"
                  controls-position="right"
                  style="width: 100%"
              />
            </el-form-item>
            <el-form-item v-if="form.dataScope === 4" label="数据权限">
              <el-tree
                  :default-checked-keys="form.depts"
                  ref="ChangeDeptList"
                  :data="depts"
                  show-checkbox
                  node-key="id"
                  :props="defaultProps"
              />
            </el-form-item>
            <el-form-item label="角色排序：" prop="sort">
              <el-input v-model="form.sort" type="number"></el-input>
            </el-form-item>
            <el-form-item label="角色状态：">
              <el-radio-group v-model="form.generalStatus">
                <el-radio :label="1">启用</el-radio>
                <el-radio :label="0">禁用</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="备注：">
              <el-input type="textarea" v-model="form.remark" :maxlength="200"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="16" style="padding-left: 20px">
            <el-form-item label="菜单权限：" style="position: relative">
              <div class="beforCon"></div>
              <div
                  style="
              width: 100%;
              height: 500px;
              overflow: auto;
              border: 1px solid #ccc;
              border-radius: 3px;
            "
              >
                <el-tree
                    :default-checked-keys="form.permissionIds"
                    ref="ChangemenuList"
                    :data="equipmentIds"
                    show-checkbox
                    node-key="id"
                    :props="defaultProps"

                >
                </el-tree>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          v-if="updataStatus == 0"
          type="primary"
          @click="submitfrom('form')"
          >确 定</el-button
        >
        <el-button v-else type="primary" @click="updatasubmitfrom('form')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  allmenuTree,
  allroledataList,
  saveRole,
  selectRoleMaxSort,
  delRole,
  updateRole,
} from "../../RequestPort/user/role";
import { selectAllPermissionButtonList } from "../../RequestPort/user/button";
import {
  selectSysDeptTreeList,
  saveDepart,
  updateDepart,
  delDepart,
  selectDepart,
} from "../../RequestPort/user/user";
export default {
  name: "role",
  data() {
    return {
      depts: [], //部门列表
      dateScopes: [
        { label: "全部", id: 1 },
        { label: "本部门", id: 2 },
        { label: "本人", id: 3 },
        { label: "自定义", id: 4 },
      ],
      level: 1,
      roleList: [],
      updataStatus: 0,
      defaultProps: {
        children: "children",
        label: "name",
        scope: "scope",
      },
      userStatus: [
        { label: "正常", id: 1 },
        { label: "停用", id: 0 },
      ],
      DataPicker: [],
      equipmentIds: [],
      authbuttonList: [],
      allbuttonIds: [],
      permissionButtons: [],

      total: 0,
      dialogVisible: false,
      form: {
        name: "",
        code: "",
        generalStatus: 1,
        remark: "",
        sort: "",
        permissionIds: [],
        buttonIds: [],
        depts: [],
        level:1
      },
      rule: {
        name: [{ required: true, message: "请输入角色名称", trigger: "blur" }],
        code: [{ required: true, message: "请选择角色编号", trigger: "blur" }],
        sort: [{ required: true, message: "请输入排序", trigger: "blur" }],
      },
      pageParams: {
        userId: localStorage.getItem("userId"),
        size: 100,
        current: 1,
        roleName: "",
        generalStatus: "",
      },
      chooseMenuId: [],
      chooseMenuData: [],
      chooseDept: [], // 多选时使用
      lastArr:[]
    };
  },
  mounted() {
    selectAllPermissionButtonList({
      userId: localStorage.getItem("userId"),
    }).then((res) => {
      this.authbuttonList = res.data;
    });
    allmenuTree({ userId: localStorage.getItem("userId") }).then((res) => {
      this.equipmentIds = res.data;
    });
    selectSysDeptTreeList({ deptListShow: "y" }).then((e) => {
      this.depts = e.data;
    });
    this.loadList(this.pageParams);
  },
  methods: {
    checkChange(node, checked) {
  //全部取消/选择
  const setChecked = (arr, ifChecked) =>{
    arr?.map((item) => {
      this.$refs.ChangemenuList.setChecked(item.id, ifChecked)
      if(!!item?.children?.length){
        setChecked(item?.children, ifChecked)
      }
    })
  }
  //如果为取消
  if (checked === false) {
    //如果当前节点有子集
    //循环子集将他们的选中取消
    setChecked(node?.children ?? [], false)
  } else {
    //否则(为选中状态)
    //判断父节点id是否为空
    if (node.pid !== 'all') {
      //如果不为空则将其选中
      //循环子集将他们的选中取消
      setChecked(node?.children ?? [], true)
    }
  }},
    search() {
      if (this.DataPicker.length > 0) {
        this.pageParams.startTime = this.DataPicker[0];
        this.pageParams.endTime = this.DataPicker[1];
      }
      this.pageParams.current = 1;
      this.loadList(this.pageParams);
    },
  
    updatasubmitfrom(form) {
      this.chooseMenuId = [];
      this.chooseMenuData = [];
    //  permissionIds
      let arr = this.$refs.ChangemenuList.getCheckedNodes(); // 全选
      let arr2 = this.$refs.ChangemenuList.getHalfCheckedNodes(); //半选

      arr.forEach((item) => {
        this.chooseMenuId.push(item.id); // 遍历项目满足条件后的操作
        this.chooseMenuData.push(item.scope); // 遍历项目满足条件后的操作
      });
      arr2.forEach((item) => {
        this.chooseMenuId.push(item.id); // 遍历项目满足条件后的操作
        this.chooseMenuData.push(item.scope); // 遍历项目满足条件后的操作
      });

      // let deptArr = this.$refs.ChangeDeptList.getCheckedNodes(); // 全选
      // let deptArr2 = this.$refs.ChangeDeptList.getHalfCheckedNodes(); //半选

      // deptArr.forEach((item) => {
      //   this.chooseDept.push(item.id); // 遍历项目满足条件后的操作
      // });
      // deptArr2.forEach((item) => {
      //   this.chooseDept.push(item.id); // 遍历项目满足条件后的操作
      // });
      
      let deptArr =[]
      if(this.$refs.ChangeDeptList){
       deptArr = this.$refs.ChangeDeptList.getCheckedKeys(true); // 全选 
       if(this.form.dataScope==4 && deptArr.length<1){
         this.$message.error('请选择数据权限');
        return
       }
      }
      
      
      //this.form.permissionIds = this.chooseMenuId;
      this.form.permissionScopes = this.chooseMenuData;
      this.form.depts = deptArr// this.chooseDept;
      
      this.$refs[form].validate((valid) => {
        if (!valid) return false;
        let form=JSON.parse(JSON.stringify(this.form))
       form.permissionIds = this.chooseMenuId;
        updateRole(form).then((res) => {
          if (res.code === 200) {
            this.$message.success(res.msg);
            this.dialogVisible = !this.dialogVisible;
            this.recet();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    del(row) {
      this.$confirm(`是否删除角色---[ ${row.name} ]?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal:false,
        type: "warning",
      }).then(() => {
        delRole({ roleId: row.roleId }).then((e) => {
          if (e.code === 200) {
            this.$message.success(e.msg);
            this.recet();
          } else {
            this.$message.error(e.msg);
          }
        });
      });
    },
    getLast(arr) {
      for (var i = 0; i < arr.length; i++) {
        let children = arr[i].children
        if (children) {
          children.forEach(item => {
              if (item.children) {
                this.getLast(children)
              } else {
                this.lastArr.push({ name: item.name, id: item.id, children })
              }
          });
         
        }else{
           this.lastArr.push({ name: arr[i].name, id: arr[i].id, children })
        }
      }
      return this.lastArr
    },
    updataUser(row) {
      this.dialogVisible = !this.dialogVisible;
      this.updataStatus = 1;
      let obj = JSON.parse(JSON.stringify(row));
      this.form = obj;
     // let Array1 = [];
      if(row.depts){
         this.form.depts = row.depts.split(",");
      }
      if (row.permissionIds) {
        let last=this.getLast(this.equipmentIds)//递归取最后一级，树赋值
        let arr=[]
        this.form.permissionIds = row.permissionIds.split(",");
        this.form.permissionIds.forEach((item, index) => {
            last.forEach((item1, index1) => {
              if(item==item1.id){
                arr.push(item)
              }
            })
        })
        this.form.permissionIds=arr
        // this.form.permissionIds.forEach((item, index) => {
        //    this.equipmentIds.forEach((item1, index1) => {
        //    //last.forEach((item1, index1) => {
        //     let ischildren = item1.children;
        //     if (ischildren && item1.id == item) {
        //       Array1.push(item);
        //       ischildren.forEach((son, index3) => {
        //         if (son.children) {
        //           Array1.push(son.id + "");
        //         }
        //       });
        //     }
        //   });
        // });
        // console.log(Array1);
        // //数组交集，或得两个数组重复的元素
        // let SamePart = this.form.permissionIds.filter((item) =>
        //   Array1.includes(item)
        // );
        // //差集=并集-交集  去除两个数组相同的元素
        // this.form.permissionIds = this.form.permissionIds.filter(
        //   (item) => !SamePart.includes(item)
        // );
      }
      if (row.permissionScopes) {
        this.chooseMenuId = row.permissionIds.split(",");
        this.chooseMenuData = row.permissionScopes.split(",");
        //this.showScope(this.equipmentIds);
      }
      console.log(this.chooseMenuId, this.chooseMenuData, 88888);
    },
    showScope(data) {
      for (var i in data) {
        this.chooseMenuId.forEach((item, index) => {
          if (data[i].id == item) {
            data[i].scope = Number(this.chooseMenuData[index]);
          }
        });
        if (data[i].children) {
          //存在子节点就递归
          this.showScope(data[i].children);
        }
      }
    },
    submitfrom(form) {
      this.$refs[form].validate((valid) => {
        if (!valid) return false;
        if(this.form.name.length>30){
          return this.$message.error('角色名称字数不应超过30')
        }
        if(this.form.code.length>30){
          return this.$message.error('角色编码字数不应超过30')
        }
        if(this.form.remark.length>200){
          return this.$message.error('备注字数不应超过200')
        }
        let arr = this.$refs.ChangemenuList.getCheckedNodes(); // 全选
        let arr2 = this.$refs.ChangemenuList.getHalfCheckedNodes(); //半选

        // let deptArr = this.$refs.ChangeDeptList.getCheckedNodes(); // 全选
        // let deptArr2 = this.$refs.ChangeDeptList.getHalfCheckedNodes(); //半选

        arr.forEach((item) => {
          this.chooseMenuId.push(item.id); // 遍历项目满足条件后的操作
          this.chooseMenuData.push(item.scope); // 遍历项目满足条件后的操作
        });
        arr2.forEach((item) => {
          this.chooseMenuId.push(item.id); // 遍历项目满足条件后的操作@
          this.chooseMenuData.push(item.scope); // 遍历项目满足条件后的操作
        });

        // deptArr.forEach((item) => {
        //   this.chooseDept.push(item.id); // 遍历项目满足条件后的操作
        // });
        // deptArr2.forEach((item) => {
        //   this.chooseDept.push(item.id); // 遍历项目满足条件后的操作
        // });
      let deptArr =[]
      if(this.$refs.ChangeDeptList){
       deptArr = this.$refs.ChangeDeptList.getCheckedKeys(true); // 全选 
        if(this.form.dataScope==4 && deptArr.length<1){
          this.$message.error('请选择数据权限');
          return
        }
      }

        this.form.permissionIds = this.chooseMenuId;
        this.form.permissionScopes = this.chooseMenuData;
        this.form.depts = deptArr//this.chooseDept;


        saveRole(this.form).then((res) => {
          if (res.code === 200) {
            this.form.permissionIds = [];
            this.form.permissionScopes = [];
            this.form.depts =  [];
            this.$message.success(res.msg);
            this.dialogVisible = !this.dialogVisible;
            this.recet();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    recet() {
      this.pageParams = {
        size: 100,
        current: 1,
        name: "",
        generalStatus: "",
      };
      this.DataPicker = [];
      this.loadList(this.pageParams);
    },
    loadList(obj) {
      allroledataList(obj).then((res) => {
        this.roleList = res.data.records;
        this.total = res.data.total;
      });
    },
    currentChange(val) {
      this.pageParams.current = val;
      this.loadList(this.pageParams);
    },
    handleSizeChange(val) {
      this.pageParams.size = val;
      this.loadList(this.pageParams);
    },
    clearnF(form) {
      this.form = {
        name: "",
        code: "",
        generalStatus: 1,
        remark: "",
        sort: "",
        permissionIds: [],
        buttonIds: [],
        level:1
      };
      this.allbuttonIds = [];
      this.$refs[form].resetFields();
      this.$refs.ChangemenuList.setCheckedKeys([]);
      this.$refs.ChangeDeptList.setCheckedKeys([]);
    },
    statusChange(val, row) {
      const statusText = val === 0 ? "禁用" : "开启";
      this.$confirm(`是否${statusText}此角色?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal:false,
        type: "warning",
      })
        .then(() => {
          this.form = row;
          if (row.permissionIds) {
            this.form.permissionIds = row.permissionIds.split(",");
          } else {
            this.form.permissionIds = [];
          }
          this.form.generalStatus = val;
          updateRole(this.form).then((res) => {
            if (res.code === 200) {
              this.loadList(this.pageParams);
              this.$message.success(res.msg);
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {
          row.generalStatus == 0
            ? (row.generalStatus = 1)
            : (row.generalStatus = 0);
        });
    },
    addUser() {
      this.updataStatus = 0;
      selectRoleMaxSort().then((res) => {
        this.form.sort = res.data;
        this.$forceUpdate();
      });

      this.dialogVisible = !this.dialogVisible;
    },
  },
};
</script>

<style scoped>
.beforCon:before {
  content: "*";
  color: #f56c6c;
  position: absolute;
  left: -92px;
  top: 0px;
  z-index: 9;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
</style>
